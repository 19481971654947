import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact`}</h2>
    <br />
    <br />
    <h4><a parentName="h4" {...{
        "href": "mailto:hello@codeworm.dev"
      }}>{`hello@codeworm.dev`}</a></h4>
    <p><a parentName="p" {...{
        "href": "tel:+48884884088"
      }}>{`+48 884 884 088`}</a></p>
    <p><a parentName="p" {...{
        "href": "tel:+48884874884"
      }}>{`+48 884 874 884`}</a></p>
    <p><a parentName="p" {...{
        "href": "tel:+48884884858"
      }}>{`+48 884 884 858`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      