import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`What we do`}</h2>
    <ProjectCard title="Web apps" bg="linear-gradient(to right, rgb(212, 20, 90) 0%, rgb(251, 176, 59) 100%)" mdxType="ProjectCard">
Web applications created especially for you.
    </ProjectCard>
    <ProjectCard title="Mobile apps" bg="linear-gradient(to right, rgb(102, 45, 140) 0%, rgb(237, 30, 121) 100%)" mdxType="ProjectCard">
Dedicated apps for Android and iOS.
    </ProjectCard>
    <ProjectCard title="E-commerce" bg="linear-gradient(to right, rgb(0, 146, 69) 0%, rgb(252, 238, 33) 100%)" mdxType="ProjectCard">
E-commerce solutions for your company with Magento, BigCommerce, Shopify or Prestashop.
    </ProjectCard>
    <ProjectCard title="Custom solutions" bg="linear-gradient(to right, rgb(213, 133, 255) 0%, rgb(0, 255, 238) 100%)" mdxType="ProjectCard">
Solutions designed for your needs.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      